import React from 'react';
import { Select, Box, HStack } from '@chakra-ui/react';
import RegularText from '../../text/regulartext';

const CustomUserDropdown = ({ placeholder, sx, onChange, value }) => {
  const items = ['Complete', 'Incomplete', 'Not relevant','Snoozed'];

  return (
    <Select
      bg='#272728'
      border="0.5px solid #222222"
      color='#848484'
      borderRadius="xl"
      boxShadow='none'
      value={value}
      _hover={{ borderColor: '#222222', boxShadow: 'none' }}
      _active={{ borderColor: '#222222', boxShadow: 'none' }}
      fontSize='sm'
      sx={sx}
      placeholder={placeholder}
      _placeholder={{ color: '#848484' }}
      onChange={onChange}
      w='max-content'
    >
      {items?.map((item, index) => (
        <option key={index} value={item} style={{ alignItems: 'center' }}>
          {item}
        </option>
      ))}
    </Select>
  );
};

export default CustomUserDropdown;

