import React, { useState, useEffect, useRef } from "react";
import {
  VStack,
  HStack,
  Input,
  IconButton,
  useToast,
  Box,
  Spinner,
  Text,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FaPaperPlane } from "react-icons/fa";
import { getSessionToken, useUser } from "@descope/react-sdk";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/Constants";
import MessageView from "../integrations/MessageView";
import CustomInput from "../../general/custominput";
import RegularText from "../../text/regulartext";

const AuditChat = ({ taskId }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const { user, isUserLoading } = useUser();
  const currentUser = user;
  const messagesEndRef = useRef(null);

  useEffect(() => {
    fetchMessages();
  }, [taskId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchMessages = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = await getSessionToken();
      const response = await axios.get(`${API_BASE_URL}core/org/audit/chat/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        params: { task_id: taskId },
      });
      setMessages(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setMessages([]);
      } else {
        console.error("Error fetching messages:", error);
        setError("Failed to fetch messages. Please try again.");
        toast({
          title: "Error fetching messages",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    try {
      const token = await getSessionToken();
      const response = await axios.post(
        `${API_BASE_URL}core/org/audit/chat/`,
        { task_id: taskId, message: input },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setMessages([...messages, response.data]);
      setInput("");
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Error sending message",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack
      spacing={2}
      align="stretch"
      w="100%"
      h="auto"
      maxH="66vh"
      p={3}
      color="white"
    >
      {isLoading ? (
        <HStack mt={4} mb={4}>
        <Spinner size="sm" thickness="2px" speed="0.4s" color="#dddddd" />
        <RegularText textAlign="center" sx={{ color: "#dddddd" }}>
          Loading messages...
        </RegularText>
      </HStack>
      ) : error ? (
        <Text color="red.500">{error}</Text>
      ) : messages.length === 0 ? (
        <RegularText color="gray.500">No messages yet. Start a conversation!</RegularText>
      ) : (
        <Box
          overflowY="auto"
          h="calc(100% - 50px)"
          py={2}
          sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          {messages.map((msg, index) => (
            <Box
              key={index}
              maxW="80%"
              alignSelf={msg.user.id === currentUser.id ? "flex-end" : "flex-start"}
            >
              <RegularText fontSize="xs" color="gray.400" mb={0.5}>
                {msg.user.name} - {new Date(msg.created).toLocaleString()}
              </RegularText>
              <Box
                bg="#2A2A2A"
                borderRadius="md"
              >
                <MessageView message={msg.message} />
              </Box>
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </Box>
      )}

      <HStack mt={1}>
        <InputGroup>
          <CustomInput
            placeholder="Send a message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            bg="#2A2A2A"
            border="none"
            borderRadius="sm"
            p={2}
          />
          <InputRightElement>
            <IconButton
              aria-label="Send message"
              icon={<FaPaperPlane />}
              onClick={handleSendMessage}
              isDisabled={!input.trim()}
              bg={input.trim() ? "#4A4A4A" : "#2A2A2A"}
              color={input.trim() ? "white" : "gray.500"}
              borderRadius="md"
              _hover={{ bg: input.trim() ? "#5A5A5A" : "#2A2A2A" }}
            />
          </InputRightElement>
        </InputGroup>
      </HStack>
    </VStack>
  );
};

export default AuditChat;