import React from 'react';
import { Select, Box, HStack } from '@chakra-ui/react';
import RegularText from '../../text/regulartext';

const CustomUserDropdown = ({ placeholder, sx, onChange, value, users }) => {
  return (
    <Select
      bg='#272728'
      border="0.5px solid #222222"
      color='#848484'
      borderRadius="xl"
      boxShadow='none'
      value={value}
      _hover={{ borderColor: '#222222', boxShadow: 'none' }}
      _active={{ borderColor: '#222222', boxShadow: 'none' }}
      fontSize='sm'
      sx={sx}
      placeholder={placeholder}
      _placeholder={{ color: '#848484' }}
      onChange={onChange}
      w='max-content'
    >
      {users?.map((user, index) => (
        <option key={index} value={String(user.id)} style={{ alignItems: 'center' }}>
          {user.name}
        </option>
      ))}
    </Select>
  );
};

export default CustomUserDropdown;

