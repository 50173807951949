import React, { useState, useEffect } from 'react';
import { HStack, VStack, Skeleton, Box, Grid } from '@chakra-ui/react';
import SubtitleText from '../../text/subtitletext';
import RegularText from '../../text/regulartext';
import DocumentsBox from './documentsbox';
import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';
import TitleText from '../../text/titletext';

const AddTeamMember = ({ }) => {

  const [taskData, setTaskData] = useState([]);
  const relevantTaskIDs = [
    "91ebeee6-0bd2-41fc-8d47-0d2388446a69",
    "47f98ceb-51a3-4a19-9f6e-565df73c2a0a",
    "9fde4072-c294-4cd6-b40e-abdb96d32e25",
    // "0d3eadfd-796c-4df6-b4d4-44e60cc4bb14",
  ];

  const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
  const { updateTaskData } = useUpdateTaskData();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [ isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (fetchedData && fetchedData.length > 0) {
      setTaskData(fetchedData);
      const allTasksComplete = fetchedData.every(task => task.complete);
      setIsSubmitted(allTasksComplete);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [fetchedData]);
  
  const handleTaskDataChange = (updatedTaskData) => {
    setTaskData(updatedTaskData);
  };

  const handleDoneClick = () => {
    updateTaskData(taskData);
  };

  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
            <TitleText>Approve additional templates provided by Delve</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            We've provided the following document templates, which you should sign and store for future use (i.e. if a customer asks for a "BAA" or "DPA," you can provide the template for them from below).
          </RegularText>
        </VStack>
      </HStack>

      {isLoading ? (
        <Grid templateColumns="repeat(3, 1fr)" gap={2} height="auto" mb={10}>
          {Array.from({ length: 4 }).map((_, index) => (
            <Skeleton h='200px' key={index} w='100%' borderRadius='xl' startColor="#333333" endColor="#222222">
            </Skeleton>
          ))}
        </Grid>
      ) : (
        <>
          <DocumentsBox onDoneClick={handleDoneClick} taskData={taskData} onTaskDataChange={handleTaskDataChange}/>
        </>
      )}
    </Box>
  );
};

export default AddTeamMember;