import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import { useSession } from '@descope/react-sdk';

import Login from './pages/login.js';
import Dashboard from './pages/dashboard.js';
import Team from './pages/team.js';
import Integrations from './pages/integrations.js';
import Compliance from './pages/compliance.js';
import ProtectedRoute from './utils/ProtectedRoute.js';
import AdminFilter from './utils/AdminFilter.js';
import TeamCompliance from './pages/teamcompliance.js';
import Submitted from './pages/submitted.js';
import Report from './pages/compliancereports/blandreport.js';
import AutomatReport from './pages/compliancereports/automatreport.js';
import Tomareport from './pages/compliancereports/tomareport.js';
import CloudCruiseReport from './pages/compliancereports/cloudcruisereport.js';
import RythmHealthReport from './pages/compliancereports/rythmhealthreport.js';
import GroveReport from './pages/compliancereports/grovereport.js';
import CureAIReport from './pages/compliancereports/cureaireport.js';
import ThumosCareReport from './pages/compliancereports/thumoscarereport.js';
import ThoughtlyReport from './pages/compliancereports/thoughtlyreport.js';
import FellaReport from './pages/compliancereports/fellareport.js';

const AppRoutes = () => {
  const { isAuthenticated, isSessionLoading } = useSession();

  const protectedRouteWrapper = (Component) => (
    <ProtectedRoute isSignedIn={isAuthenticated} isLoading={isSessionLoading}>
      <Component />
    </ProtectedRoute>
  );

  const AdminFilterWrapper = (Component) => (
    <AdminFilter>
      <Component />
    </AdminFilter>
  );

  return (
    <Router>
      <Routes>
        <Route path="/test-app" element={AdminFilterWrapper(App)} />
        <Route path="/dashboard" element={AdminFilterWrapper(Dashboard)} />
        <Route path="/team" element={AdminFilterWrapper(Team)} />
        <Route path="/integrations" element={AdminFilterWrapper(Integrations)} />
        <Route path="/compliance" element={AdminFilterWrapper(Compliance)} />
        <Route path="/team-compliance" element={protectedRouteWrapper(TeamCompliance)} />
        <Route path="/submitted" element={protectedRouteWrapper(Submitted)} />

        {/* default to compliance, logs out unwanted users */}
        <Route path="*" element={AdminFilterWrapper(Compliance)} />

        {/* unprotected routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/blandai" element={<Report />} />
        <Route path="/automat" element={<AutomatReport />} />
        <Route path="/toma" element={<Tomareport />} />
        <Route path="/cloudcruise" element={<CloudCruiseReport />} />
        <Route path="/rythmhealth" element={<RythmHealthReport />} />
        <Route path="/groveai" element={<GroveReport />} />
        <Route path="/cureai" element={<CureAIReport />} />
        <Route path="/thumoscare" element={<ThumosCareReport />} />
        <Route path="/thoughtly" element={<ThoughtlyReport />} />
        <Route path="/fella" element={<FellaReport />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;