import React, { useState, useRef, useEffect } from 'react';
import { Box, HStack, Tab, Tabs, TabList } from '@chakra-ui/react';
import CompanyTab from './company/companytab';
import TeamTab from './team/teamtab';
import DocumentsTab from './documents/documentstab';
import InfrastructureTab from './integrations/updatedintegrations';
import SecurityTab from './security/securitytab';
import AuditsTab from './audits/auditstab';
import WhiteSubtitleText from '../text/whitesubtitletext';
import RegularText from '../text/regulartext';
import CustomButton from '../general/custombutton';

const ComplianceDistributer = ({ currentPage, setPage }) => {
  const [activeTab, setActiveTab] = useState(0);
  const sectionsRefs = useRef([]);

  const companyInformationRef = useRef(null);

  const addTeamMemberRef = useRef(null);
  const assignTeamRolesRef = useRef(null);
  const manageEmployeeRef = useRef(null);

  const signDocumentsRef = useRef(null);
  const approveTemplatesRef = useRef(null);

  const addIntegrationsRef = useRef(null);

  const trackSecurityTasksRef = useRef(null);
  const listCompanyResourcesRef = useRef(null);
  const listRisksRef = useRef(null);
  const simulateSecurityIncidentRef = useRef(null);
  const listPreviousHacksRef = useRef(null);

  const soc2AuditRef = useRef(null);


  const refToTabName = {
    companyInformationRef: 'Provide company information',

    addTeamMemberRef: 'Add team members',
    assignTeamRolesRef: 'Assign team roles',
    manageEmployeeRef: 'Follow team management procedures',

    signDocumentsRef: 'Sign documents',
    approveTemplatesRef: 'Approve additional templates provided by Delve',

    addIntegrationsRef: 'Add integrations',

    trackSecurityTasksRef: 'Track security-related tasks in your task tracker',
    listCompanyResourcesRef: 'List company resources',
    listRisksRef: 'List the risks to your company',
    simulateSecurityIncidentRef: 'Simulate a security incident',
    listPreviousHacksRef: 'List previous hacks and security incidents',

    soc2AuditRef: 'SOC 2 audit dashboard'
  };

  const availableRefs = [];
  if (currentPage === 1) {
    availableRefs.push({ ref: companyInformationRef, name: refToTabName.companyInformationRef });
  }

  if (currentPage === 2) {
    availableRefs.push({ ref: addTeamMemberRef, name: refToTabName.addTeamMemberRef });
    availableRefs.push({ ref: assignTeamRolesRef, name: refToTabName.assignTeamRolesRef });
    availableRefs.push({ ref: manageEmployeeRef, name: refToTabName.manageEmployeeRef });
  }

  if (currentPage === 3) {
    availableRefs.push({ ref: signDocumentsRef, name: refToTabName.signDocumentsRef });
    availableRefs.push({ ref: approveTemplatesRef, name: refToTabName.approveTemplatesRef });
  }

  if (currentPage === 4) {
    availableRefs.push({ ref: addIntegrationsRef, name: refToTabName.addIntegrationsRef });
  }

  if (currentPage === 5) {
    availableRefs.push({ ref: trackSecurityTasksRef, name: refToTabName.trackSecurityTasksRef });
    availableRefs.push({ ref: listCompanyResourcesRef, name: refToTabName.listCompanyResourcesRef });
    availableRefs.push({ ref: listRisksRef, name: refToTabName.listRisksRef });
    availableRefs.push({ ref: simulateSecurityIncidentRef, name: refToTabName.simulateSecurityIncidentRef });
    availableRefs.push({ ref: listPreviousHacksRef, name: refToTabName.listPreviousHacksRef });
  }

  if (currentPage === 6) {
    availableRefs.push({ ref: soc2AuditRef, name: refToTabName.soc2AuditRef });
  }

  const renderTabs = () => {
    return availableRefs.map((item, index) => (
      <Tab key={index} onClick={() => handleTabClick(index)} sx={{
        _active: { bg: 'transparent' },
        _focus: { boxShadow: 'none' },
        textAlign: 'left',
        _selected: { borderColor: '#D73601' },
        borderColor: '#1C1C1E'
      }}>
        <Box w='100%' align='left'><RegularText>{item.name}</RegularText></Box>
      </Tab>
    ));
  };

  const lastUpdatedIndex = useRef(activeTab);
  const [isTabClick, setIsTabClick] = useState(false); // Flag to indicate tab click

  useEffect(() => {
    setActiveTab(0); // Reset active tab to 0 when currentPage changes
  }, [currentPage]); // Dependency array includes currentPage to trigger effect on its change
  
  useEffect(() => {
    switch (currentPage) {
      case 1:
        sectionsRefs.current = [companyInformationRef];
        break;
      case 2:
        sectionsRefs.current = [addTeamMemberRef, assignTeamRolesRef, manageEmployeeRef];
        break;
      case 3:
        sectionsRefs.current = [signDocumentsRef, approveTemplatesRef];
        break;
      case 4:
        sectionsRefs.current = [addIntegrationsRef];
        break;
      case 5:
        sectionsRefs.current = [trackSecurityTasksRef, listCompanyResourcesRef, listRisksRef, simulateSecurityIncidentRef, listPreviousHacksRef];
        break;
     case 6:
        sectionsRefs.current = [soc2AuditRef];
        break;
      default:
        sectionsRefs.current = [];
    }
  }, [currentPage, companyInformationRef, addTeamMemberRef, assignTeamRolesRef, manageEmployeeRef, signDocumentsRef, approveTemplatesRef, addIntegrationsRef, trackSecurityTasksRef, listCompanyResourcesRef, listRisksRef, simulateSecurityIncidentRef, listPreviousHacksRef, soc2AuditRef]);

  const handleScroll = () => {
    if (isTabClick) return;
    const headerOffset = 50;
    const yOffset = -150;
    const offsets = sectionsRefs.current.map(ref => ref.current ? ref.current.offsetTop - headerOffset + yOffset : 0);
    const activeIndex = offsets.findIndex((offset, index) => {
      const nextOffset = offsets[index + 1] || Infinity;
      return window.pageYOffset >= offset && window.pageYOffset < nextOffset;
    });

    if (activeIndex !== -1 && activeIndex !== lastUpdatedIndex.current) {
      lastUpdatedIndex.current = activeIndex;
      setActiveTab(activeIndex);
    }
  };

  useEffect(() => {
    const handleScrollEvent = () => {
      if (!isTabClick) {
        handleScroll();
      }
    };
  
    window.addEventListener('scroll', handleScrollEvent);
    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, [isTabClick]); // Include isTabClick in the dependency array
  
  const handleTabClick = (index) => {
    setIsTabClick(true);
    setActiveTab(index);
    const yOffset = -150;
    const element = availableRefs[index].ref.current;
    if (element) {
      const top = element.offsetTop + yOffset;
      window.scrollTo({ top: top, behavior: 'smooth' });
      setTimeout(() => setIsTabClick(false), 1000); // Increase timeout to 1000ms
    }
  };

  const renderPageContent = () => {
    switch (currentPage) {
      case 1:
        return <CompanyTab companyInformationRef={companyInformationRef} />;
      case 2:
        return <TeamTab addTeamMemberRef={addTeamMemberRef} assignTeamRolesRef={assignTeamRolesRef} manageEmployeeRef={manageEmployeeRef} />;
      case 3:
        return <DocumentsTab signDocumentsRef={signDocumentsRef} approveTemplatesRef={approveTemplatesRef} />;
      case 4:
        return <InfrastructureTab addIntegrationsRef={addIntegrationsRef} />;
      case 5:
        return <SecurityTab trackSecurityTasksRef={trackSecurityTasksRef} listCompanyResourcesRef={listCompanyResourcesRef} listRisksRef={listRisksRef} simulateSecurityIncidentRef={simulateSecurityIncidentRef} listPreviousHacksRef={listPreviousHacksRef} />;
      case 6:
       return <AuditsTab soc2AuditRef={soc2AuditRef} />;
      default:
        return null;
    }
  };

  const pageTitles = {
    1: 'Company',
    2: 'Team',
    3: 'Documents',
    4: 'Infrastructure',
    5: 'Security',
    6: 'Audits'
  };

  return (
    <HStack w='90%' ml='5%' mr='5%' h='100%' align='top'>
      <Box w='20%' h='100%' position='fixed'>
        <WhiteSubtitleText sx={{mb:2}}>{pageTitles[currentPage]}</WhiteSubtitleText>
        <Tabs orientation="vertical" colorScheme='purple' size='sm' mb={6} index={activeTab}>
          <TabList>
            {renderTabs()}
          </TabList>
        </Tabs>
        {currentPage !== 5 && <CustomButton text='Next' onClick={() => setPage(currentPage + 1)}></CustomButton>}
      </Box>
      <Box w='65%' ml='25%'>
        {renderPageContent()}
      </Box>
    </HStack>
  );
};

export default ComplianceDistributer;
