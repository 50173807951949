import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import posthog from "posthog-js";
import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import useHandleFiles from "../../../hooks/useHandleFiles";
import CustomUpload from "../../general/customupload";
import RegularText from "../../text/regulartext";
import SubtitleText from "../../text/subtitletext";
import WhiteSubtitleText from "../../text/whitesubtitletext";
import AuditChat from "./auditchat";
import CustomAuditDropdown from "./customauditdropdown";
import CustomTestStatusDropdown from "./customteststatusdropdown";
import CustomUserDropdown from "./customuserdropdown";

import { getSessionToken } from "@descope/react-sdk";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/Constants";
import HTMLRenderer from "../documents/htmlrenderer";
import { useCompanyInfo } from '../../../contexts/CompanyInfoContext';

const AuditTable = ({ users, controls, so2ComplianceOfficer }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTest, setSelectedTest] = useState(null);
  const [showTestProcedure, setShowTestProcedure] = useState(false);
  const [showMoreEvidence, setShowMoreEvidence] = useState(false);
  const { handleFileUpload, handleRemoveFile } = useHandleFiles();
  const toast = useToast();
  const { companyInfo, loadCompanyInfo } = useCompanyInfo();

  const fetchAuditTaskData = async (taskId) => {
    try {
      const token = await getSessionToken();
      const response = await axios.get(`${API_BASE_URL}compliance/map/audit/${taskId}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedTest(response.data);
      posthog.capture("Fetched Audit Task Data", {
        taskId: taskId,
      });
    } catch (error) {
      console.error("There was an error fetching the audit task data!", error);
      posthog.capture("Error Fetching Audit Task Data", {
        error: error,
      });
    }
  };

  const handleRowClick = (testId) => {
    fetchAuditTaskData(testId);
    onOpen();
  };

  const updateUserForTask = async (taskId, userId) => {
    try {
      const token = await getSessionToken();
      const response = await axios.post(
        `${API_BASE_URL}core/org/update-associated-user/`,
        { organization_task_id: taskId, associated_user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("User updated successfully:", response.data);

      setSelectedTest((prevState) => ({
        ...prevState,
        associated_user: userId,
      }));

      toast({
        title: "User updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      posthog.capture("Updated User for Task", {
        taskId: taskId,
        userId: userId || "No user selected",
      });
    } catch (error) {
      console.error("There was an error updating the user for the task!", error);
      posthog.capture("Error Updating User for Task", {
        error: error,
      });
    }
  };

  const updateTestStatus = async (taskId, status) => {
    console.log("Updating status to:", status);
    try {
      const token = await getSessionToken();
      const statusPayload = {
        complete: false,
        relevant: true,
        snoozed: false,
        organization_task_id: taskId,
      };

      // Set the appropriate flag based on the selected status
      if (status === "Complete") {
        statusPayload.complete = true;
      } else if (status === "Not relevant") {
        statusPayload.relevant = false;
      } else if (status === "Snoozed") {
        statusPayload.snoozed = true;
      }
      // If status is "Incomplete", all flags remain as initialized above

      const response = await axios.post(`${API_BASE_URL}core/org/update-test-status/`, statusPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Test status updated successfully:", response.data);

      setSelectedTest((prevState) => ({
        ...prevState,
        ...statusPayload,
      }));

      toast({
        title: "Test status updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("There was an error updating the test status!", error);
      toast({
        title: "Error updating test status",
        description: "There was an error updating the test status!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (companyInfo.isLoading) {
      loadCompanyInfo();
    }
  }, [companyInfo.isLoading, loadCompanyInfo]);


  const updateAuditStatusForTask = async (taskId, auditStatus) => {
    try {
      const token = await getSessionToken();
      const response = await axios.post(
        `${API_BASE_URL}core/org/update-audit-status/`,
        { organization_task_id: taskId, audit_status: auditStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Audit status updated successfully:", response.data);

      setSelectedTest((prevState) => ({
        ...prevState,
        audit_status: auditStatus,
      }));

      toast({
        title: "Audit status updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      posthog.capture("Updated Audit Status for Task", {
        taskId: taskId,
        auditStatus: auditStatus,
      });
    } catch (error) {
      console.error("There was an error updating the audit status for the task!", error);
      posthog.capture("Error Updating Audit Status for Task", {
        error: error,
      });
    }
  };

  const [showLogs, setShowLogs] = useState({});
  const [showAttributes, setShowAttributes] = useState({});

  return (
    <>
      {controls && controls.length > 0 ? (
        controls.map((criteriaGroup, criteriaIndex) => (
          <Box key={criteriaIndex} mb="40px">
            <SubtitleText sx={{ mt: "20px", mb: "10px" }}>{criteriaGroup.criteria}</SubtitleText>
            {criteriaGroup.control.map(
              (control, controlIndex) =>
                control.tests &&
                control.tests.length > 0 && (
                  <Box key={controlIndex} mb="40px">
                    <HStack>
                      <WhiteSubtitleText sx={{ mb: "5px", mt: "10px" }}>Control: {control.title}</WhiteSubtitleText>
                    </HStack>
                    <RegularText sx={{ color: "#dddddd" }}>{control.categories}</RegularText>
                    <RegularText sx={{ mt: 2 }}>
                      <span style={{ fontWeight: "bold" }}>Control owner:</span> {so2ComplianceOfficer}
                    </RegularText>
                    <RegularText sx={{ mb: 4 }}>
                      <span style={{ fontWeight: "bold" }}>Control description:</span> {control.description}
                    </RegularText>

                    <Box>
                      <RegularText
                        as="a"
                        onClick={() => setShowTestProcedure(!showTestProcedure)}
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          mb: 2,
                        }}
                      >
                        {showTestProcedure ? "Hide test procedure" : "View test procedure"}
                        <ChevronDownIcon />
                      </RegularText>
                      <Collapse in={showTestProcedure} animateOpacity>
                        <RegularText>
                          {control.testProcedure.split("\n").map((line, index) => (
                            <React.Fragment key={index}>
                              {/\d+\./.test(line) ? `${line}\n` : line}
                              <br />
                            </React.Fragment>
                          ))}
                        </RegularText>
                      </Collapse>
                    </Box>

                    <Table variant="simple" colorScheme="white" mb="60px" mt="20px">
                      <Thead>
                        <Tr>
                          <Th color="#222222" width="60%" textAlign="left">
                            <RegularText fontWeight="bold" sx={{ fontSize: "xs" }}>
                              Test
                            </RegularText>
                          </Th>
                          <Th color="#222222" width="20%" textAlign="left">
                            <RegularText fontWeight="bold" sx={{ fontSize: "xs" }}>
                              Test status
                            </RegularText>
                          </Th>
                          <Th color="#222222" width="20%" textAlign="left">
                            <RegularText fontWeight="bold" sx={{ fontSize: "xs" }}>
                              Auditor status
                            </RegularText>
                          </Th>
                          <Th color="#222222" width="20%" textAlign="left">
                            <RegularText fontWeight="bold" sx={{ fontSize: "xs" }}>
                              Owner
                            </RegularText>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {control.tests.map((test, testIndex) => (
                          <Tr
                            key={testIndex}
                            color="#222222"
                            onClick={() => handleRowClick(test.id)}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#151517",
                              },
                            }}
                          >
                            <Td textAlign="left">
                              <RegularText sx={{ color: "#dddddd" }}>{test.title}</RegularText>
                            </Td>
                            <Td textAlign="left">
                              <HStack spacing="8px" justifyContent="left">
                                {(() => {
                                  if (test.complete) {
                                    console.log("Rendering Complete status");
                                    return (
                                      <>
                                        <Box w="10px" h="10px" borderRadius="full" bg="green.500"></Box>
                                        <RegularText>Complete</RegularText>
                                      </>
                                    );
                                  }
                                  if (!test.relevant) {
                                    console.log("Rendering Not Relevant status");
                                    return (
                                      <>
                                        <Box w="10px" h="10px" borderRadius="full" bg="gray.500"></Box>
                                        <RegularText>Not relevant</RegularText>
                                      </>
                                    );
                                  }
                                  if (test.snoozed) {
                                    console.log("Rendering Snoozed status");
                                    return (
                                      <>
                                        <Box w="10px" h="10px" borderRadius="full" bg="yellow.500"></Box>
                                        <RegularText>Snoozed</RegularText>
                                      </>
                                    );
                                  }
                                  console.log("Rendering Incomplete status");
                                  return (
                                    <>
                                      <Box w="10px" h="10px" borderRadius="full" bg="red.500"></Box>
                                      <RegularText>Incomplete</RegularText>
                                    </>
                                  );
                                })()}
                              </HStack>
                            </Td>
                            <Td textAlign="left">
                              <HStack spacing="8px" justifyContent="left">
                                <Box
                                  w="10px"
                                  h="10px"
                                  bg={
                                    test.audit_status === "accepted"
                                      ? "green.500"
                                      : test.audit_status === "flagged"
                                      ? "yellow.500"
                                      : "red.500"
                                  }
                                  borderRadius="full"
                                ></Box>
                                {test.audit_status === "accepted" ? (
                                  <RegularText>Accepted</RegularText>
                                ) : test.audit_status === "flagged" ? (
                                  <RegularText>Flagged</RegularText>
                                ) : (
                                  <RegularText sx={{ whiteSpace: "nowrap" }}>Not reviewed</RegularText>
                                )}
                              </HStack>
                            </Td>
                            <Td>
                              <Avatar size="sm" name={test.associated_user} />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )
            )}
          </Box>
        ))
      ) : (
        <HStack mt={4} mb={4}>
          <Spinner size="sm" thickness="2px" speed="0.4s" color="#dddddd" />
          <RegularText textAlign="center" sx={{ color: "#dddddd" }}>
            Loading controls...
          </RegularText>
        </HStack>
      )}

      {selectedTest && users && (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
          <DrawerOverlay />
          <DrawerContent bg="#1C1C1E">
            <DrawerHeader mt="20px">
              <WhiteSubtitleText>{selectedTest.name}</WhiteSubtitleText>
            </DrawerHeader>
            <DrawerCloseButton color="#848484" />
            <DrawerBody>
              <Box mt={0} mb={1}>
                <SubtitleText>Test overview</SubtitleText>
              </Box>
              <Table variant="simple" colorScheme="white" mb="20px" size="sm">
                <Tbody color="#1c1c1e">
                  <Tr>
                    <Td w="15%" style={{ verticalAlign: "top" }}>
                      <RegularText sx={{ color: "#dddddd" }}>Test description</RegularText>
                    </Td>
                    <Td w="85%">
                      <RegularText>{selectedTest.description}</RegularText>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td width="15%" style={{ verticalAlign: "top" }}>
                      <RegularText sx={{ color: "#dddddd" }}>Test status</RegularText>
                    </Td>
                    <Td width="85%">
                      <CustomTestStatusDropdown
                        value={
                          selectedTest.complete
                            ? "Complete"
                            : !selectedTest.relevant
                            ? "Not relevant"
                            : selectedTest.snoozed
                            ? "Snoozed"
                            : "Incomplete"
                        }
                        onChange={(e) => updateTestStatus(selectedTest.id, e.target.value)}
                        placeholder="Select status"
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td width="15%" style={{ verticalAlign: "top" }}>
                      <RegularText sx={{ color: "#dddddd" }}>Owner</RegularText>
                    </Td>
                    <Td width="85%">
                      <CustomUserDropdown
                        placeholder="Select user"
                        onChange={(e) => updateUserForTask(selectedTest.id, e.target.value)}
                        value={selectedTest.associated_user}
                        users={users}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td w="15%" style={{ verticalAlign: "top" }}>
                      <RegularText sx={{ color: "#dddddd" }}>Evidence</RegularText>
                    </Td>
                    <Td w="85%">
                      <HStack>
                        <CustomUpload
                          placeholder="Select"
                          sx={{ bg: "#272728", mr: 0 }}
                          onFileSelect={(files) => handleFileUpload(files, selectedTest.id)}
                          customRemoveFile={(file) => handleRemoveFile(selectedTest.id, file.id)}
                          text=" "
                        />
                        {selectedTest.files &&
                          selectedTest.files.length > 0 &&
                          selectedTest.files.map((file, index) => (
                            <HStack
                              key={`${selectedTest.global_task_id}-${index}`}
                              mt={2}
                              spacing={2}
                              alignItems="center"
                              display="inline-flex"
                              variant="unstyled"
                              borderRadius="xl"
                              border="1px solid #222222"
                              bg="transparent"
                              w="auto"
                              h="35px"
                              p={3}
                              color="#222222"
                            >
                              <Link fontSize="sm" href={file.file_url} isExternal color="blue.500">
                                {file.file_name.split("/").pop()}
                              </Link>
                              <Icon
                                as={FaTimes}
                                w={3}
                                h={3}
                                color="#848484"
                                cursor="pointer"
                                onClick={() => handleRemoveFile(selectedTest.id, file.id)}
                              />
                            </HStack>
                          ))}
                      </HStack>
                    </Td>
                  </Tr>
                  {selectedTest.document_html && (
                    <Tr>
                      <Td style={{ verticalAlign: "top" }} w="15%">
                        <RegularText sx={{ color: "#dddddd" }}>
                          Policy
                        </RegularText>
                      </Td>
                      {!companyInfo.isLoading && (
                        <Td w="85%" h='400px'>
                          <HTMLRenderer html={selectedTest.associated_user_name ? selectedTest.document_html.replace(/\[COMPANY\]/g, companyInfo.companyLegalName).replace("[Approver]", selectedTest.associated_user_name).replace("[Version]", "1.0").replace("[Effective Date]", "June 1, 2024") : selectedTest.document_html} />
                        </Td>
                      )}
                    </Tr>
                  )}
                  <Tr>
                    <Td style={{ verticalAlign: "top" }} w="15%">
                      <RegularText sx={{ color: "#dddddd" }} w="15%">
                        Test history
                      </RegularText>
                    </Td>
                    {selectedTest.evidence.length > 0 ? (
                      <Td w="85%">
                        <VStack align="start" spacing={4}>
                          {[
                            ...new Map(
                              selectedTest.evidence.map((item) => [item["user"] + item["action"] + item["date"], item])
                            ).values(),
                          ]
                            .slice(0, showMoreEvidence ? selectedTest.evidence.length : 5)
                            .map((entry, index) => (
                              <Box key={index} p={4} bg="#272728" borderRadius="xl">
                                <HStack align="center">
                                  <Avatar size="sm" name={entry.user} />
                                  <VStack align="start" spacing={1}>
                                    <RegularText sx={{ color: "#dddddd" }}>
                                      {entry.user} (user ID: {entry.user_id}) {entry.action}
                                    </RegularText>
                                    <RegularText>Test: {selectedTest.title}</RegularText>
                                    <RegularText>Timestamp: {entry.date}</RegularText>
                                  </VStack>
                                </HStack>
                              </Box>
                            ))}
                          {selectedTest.evidence.length > 5 && (
                            <Button
                              onClick={() => setShowMoreEvidence(!showMoreEvidence)}
                              variant="link"
                              color="blue.500"
                              fontSize="sm"
                            >
                              {showMoreEvidence ? "Show less" : "Show more"}
                            </Button>
                          )}
                        </VStack>
                      </Td>
                    ) : (
                      <Td w="85%">
                        <RegularText>No history available</RegularText>
                      </Td>
                    )}
                  </Tr>
                </Tbody>
              </Table>

              {selectedTest.test_runs && selectedTest.test_runs.length > 0 ? (
                <Box mt={4} mb={4}>
                  <SubtitleText>Automated test scan</SubtitleText>
                  {selectedTest.test_runs
                    .sort((a, b) => new Date(b.updated) - new Date(a.updated))
                    .slice(0, 1)
                    .map((run, index) => (
                      <Box w="100%" key={index}>
                        <Table variant="simple" colorScheme="white" mt="10px" size="sm">
                          <Tbody color="#1c1c1e">
                            <Tr>
                              <Td w="15%" style={{ verticalAlign: "top" }}>
                                <RegularText sx={{ color: "#dddddd" }}>Test results</RegularText>
                              </Td>
                              <Td w="85%">
                                <RegularText>{run.status}</RegularText>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td w="15%" style={{ verticalAlign: "top" }}>
                                <RegularText sx={{ color: "#dddddd" }}>Test date</RegularText>
                              </Td>
                              <Td w="85%">
                                <RegularText>{run.updated}</RegularText>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td w="15%" style={{ verticalAlign: "top" }}>
                                <RegularText sx={{ color: "#dddddd" }}>Test details</RegularText>
                              </Td>
                              <Td w="85%">
                                {run.identifiers.map((identifier, idIndex) => (
                                  <Box
                                    key={idIndex}
                                    mb={4}
                                    p={4}
                                    bg="#272728"
                                    borderRadius="xl"
                                    w="100%"
                                    maxWidth="100%"
                                    overflowX="auto"
                                  >
                                    <RegularText sx={{ color: "#dddddd" }}>
                                      Instance name: <span style={{ color: "#848484" }}>{identifier.name}</span>
                                    </RegularText>
                                    <RegularText sx={{ color: "#dddddd" }}>
                                      Instance identifier: <span style={{ color: "#848484" }}>{identifier.identifier}</span>
                                    </RegularText>
                                    <RegularText sx={{ color: "#dddddd" }}>
                                      Region: <span style={{ color: "#848484" }}>{identifier.region}</span>
                                    </RegularText>
                                    <RegularText sx={{ color: "#dddddd" }}>
                                      Passing: <span style={{ color: "#848484" }}>{identifier.passing ? "Yes" : "No"}</span>
                                    </RegularText>

                                    <Box mt={2} w="100%" overflowX="hidden">
                                      <RegularText
                                        as="a"
                                        onClick={() =>
                                          setShowLogs({
                                            ...showLogs,
                                            [identifier.identifier]: !showLogs[identifier.identifier],
                                          })
                                        }
                                        sx={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {showLogs[identifier.identifier] ? "Hide logs" : "View logs"}
                                        <ChevronDownIcon />
                                      </RegularText>
                                      <Collapse
                                        in={showLogs[identifier.identifier]}
                                        animateOpacity
                                        w="100%"
                                        overflowX="hidden"
                                      >
                                        <Box mt={2} w="100%" overflowX="hidden">
                                          <RegularText
                                            sx={{ whiteSpace: "pre-wrap", fontFamily: "monospace", fontSize: "xs" }}
                                          >
                                            {JSON.stringify(identifier.evidence, null, 2)}
                                          </RegularText>
                                        </Box>
                                      </Collapse>
                                    </Box>

                                    <Box mt={2} w="100%" overflowX="hidden">
                                      <RegularText
                                        as="a"
                                        onClick={() =>
                                          setShowAttributes({
                                            ...showAttributes,
                                            [identifier.identifier]: !showAttributes[identifier.identifier],
                                          })
                                        }
                                        sx={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {showAttributes[identifier.identifier] ? "Hide attributes" : "View attributes"}
                                        <ChevronDownIcon />
                                      </RegularText>
                                      <Collapse
                                        in={showAttributes[identifier.identifier]}
                                        animateOpacity
                                        w="100%"
                                        overflowX="hidden"
                                      >
                                        <Box mt={2} w="100%" overflowX="hidden">
                                          <RegularText
                                            sx={{ whiteSpace: "pre-wrap", fontFamily: "monospace", fontSize: "xs" }}
                                          >
                                            {JSON.stringify(identifier.attributes, null, 2)}
                                          </RegularText>
                                        </Box>
                                      </Collapse>
                                    </Box>
                                  </Box>
                                ))}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Box>

                      // <Box key={index} p={4} bg="#272728" borderRadius="xl" mb={4} mt={2}>
                      //   <RegularText sx={{ color: '#dddddd' }}>Status: <span style={{ color: '#848484' }}>{run.status}</span></RegularText>
                      //   <RegularText sx={{ color: '#dddddd' }}>Test run date: <span style={{ color: '#848484' }}>{run.updated}</span></RegularText>

                      // </Box>
                    ))}
                </Box>
              ) : (
                // <Box mt={4} mb={4}>
                //   <SubtitleText>Test Runs</SubtitleText>
                //   <RegularText>No test runs available.</RegularText>
                // </Box>
                <h1 />
              )}
              <VStack spacing={2} align="stretch" w="100%" bg="#101012" h="auto" borderRadius="xl" mb={4} p={4} pb={0}>
                <SubtitleText>Auditor messages</SubtitleText>
                <AuditChat taskId={selectedTest.id} />
              </VStack>
              <VStack spacing={2} align="stretch" w="100%" bg="#101012" h="auto" borderRadius="xl" mb={4} p={4} pb={0}>
                <Box mt={1} mb={2}>
                  <SubtitleText>Audit status</SubtitleText>
                  <Table variant="simple" colorScheme="white" mb="20px" mt="10px" size="sm">
                    <Tbody color="#101012">
                      <Tr>
                        <Td w="15%" style={{ verticalAlign: "top" }}>
                          <RegularText sx={{ color: "#dddddd" }}>Review status</RegularText>
                        </Td>
                        <Td w="85%">
                          <CustomAuditDropdown
                            value={selectedTest.audit_status}
                            onChange={(e) => updateAuditStatusForTask(selectedTest.id, e.target.value)}
                            placeholder="Select status"
                          />
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default AuditTable;
